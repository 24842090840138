import { QueryClient } from '@tanstack/react-query';

import * as cart from './cart';
import * as contents from './contents';
import * as custom from './custom';
import * as customer from './customer';
import * as directories from './directories';
import { ResponseApiDto } from './dto';
import * as events from './events';
import * as file from './file';
import * as giftCards from './giftCards';
import * as info from './info';
import * as menus from './menus';
import * as messages from './messages';
import * as orders from './orders';
import * as pages from './pages';
import * as payPal from './payPal';
import * as products from './products';
import * as reviews from './reviews';
import * as session from './session';
import * as shops from './shops';
import * as suggestions from './suggestions';
import * as translations from './translations';
import { init, updateHeaders } from './utils/util';

// === V1 ===
// export type ApiResponse<T> = T;

// === V2 ===
export type ApiResponse<T> = ResponseApiDto<T>;

export const queryClient = new QueryClient({
  // logger: {
  //   error: () => {
  //     //
  //   },
  //   log: () => {
  //     //
  //   },
  //   warn: () => {
  //     //
  //   },
  // },
});

export * from './dto';

export const api = {
  cart: cart,
  contents: contents,
  custom: custom,
  customer: customer,
  directories: directories,
  events: events,
  file: file,
  giftCards: giftCards,
  info: info,
  init: init,
  menus: menus,
  messages: messages,
  orders: orders,
  pages: pages,
  payPal: payPal,
  products: products,
  queryClient: queryClient,
  reviews: reviews,
  session: session,
  shops: shops,
  suggestions: suggestions,
  translations: translations,
  updateHeaders: updateHeaders,
};
